import React, { useRef } from "react"
import { Link } from "gatsby"

import "./style.coreApps.scss"
import Icon1 from "../assets/icon1.png"
import Icon2 from "../assets/icon2.png"
import Icon3 from "../assets/icon3.png"
import ContactModal from "../../contactModal"

function CoreApps() {
  const modalRef = useRef()
  return (
    <div id="aiot-products">
      <div className="title">核心应用</div>
      <div className="aiot-container">
        <div className="card-wrapper">
          <div className="card hot">
            <div className="first-part">
              <img src={Icon1} alt="icon" />
              <div className="text">装满满</div>
              <div className="desc">智能模拟装箱软件服务</div>
            </div>
            <div className="second-part">
              <p>
                一键计算最佳装箱配载优化方案的装箱装柜软件，快速解决估柜、装箱、打包设计等难题
              </p>
              <Link
                className="check"
                to="/products/zhuangmanman"
                href="#responsive-header"
              >
                查看详情
              </Link>
            </div>
          </div>
          <div className="card">
            <div className="first-part">
              <img src={Icon2} alt="icon" />
              <div className="text">码高高</div>
              <div className="desc">智能混码机器人算法</div>
            </div>
            <div className="second-part">
              <p>
                码垛规划软件实时计算单/多SKU码垛规划方案，保证稳定垛型，高效解决货物下线码垛问题
              </p>
              <Link
                className="check"
                to="/products/magaogao"
                href="#responsive-header"
              >
                查看详情
              </Link>
            </div>
          </div>
          <div className="card">
            <div className="first-part">
              <img src={Icon3} alt="icon" />
              <div className="text">智能决策解决方案</div>
              <div className="desc">联系客服了解更多</div>
            </div>
            <div className="second-part">
              <p>针对“端到端”业务流程进行统一建模和全局多目标优化计算</p>
              <div
                className="check contact-us-now"
                gtm-id="home-contact-us"
                onClick={() => modalRef.current.openModal()}
              >
                联系我们
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactModal ref={modalRef} />
    </div>
  )
}

export default CoreApps
