import React, { useEffect, useRef } from "react"
import Glide, {
  Controls,
  Autoplay,
} from "@glidejs/glide/dist/glide.modular.esm"

import "./style.partners.scss"
import {
  Partner_1,
  Partner_2,
  Partner_3,
  Partner_4,
  Partner_5,
  Partner_6,
  Partner_7,
  Partner_8,
  Partner_9,
  Partner_10,
  Partner_11,
  Partner_12,
  Partner_13,
  Partner_14,
  Partner_15,
  Partner_16,
  Partner_17,
  Partner_18,
  Partner_19,
  Partner_20,
  Partner_21,
  Partner_22,
  Partner_23,
  Partner_24,
  Partner_25,
  Partner_26,
  Partner_27,
  Partner_28,
} from "../assets"

function Partners() {
  const sliderRef = useRef(null)

  useEffect(() => {
    const slider = new Glide(sliderRef.current, {
      type: "carousel",
      autoplay: 3000,
      hoverpause: true,
    })
    slider.mount({ Controls, Autoplay })
    return () => {
      slider.destroy()
    }
  }, [])

  return (
    <div id="aiot-partners">
      <div className="title">合作伙伴</div>
      <div className="aiot-content">
        <div className="glide" ref={sliderRef}>
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
              <li className="aiot-products">
                <div className="slide-wrapper">
                  <div className="logos">
                    <img src={Partner_1} alt="DHL" />
                    <img src={Partner_6} alt="埃森哲" />
                  </div>
                  <div className="logos">
                    <img src={Partner_2} alt="SONY索尼" />
                    <img src={Partner_7} alt="铁士物流" />
                  </div>
                  <div className="logos">
                    <img src={Partner_3} alt="泸州老窖" />
                    <img src={Partner_8} alt="FANUC" />
                  </div>
                  <div className="logos">
                    <img src={Partner_4} alt="INV东莞创新供应链" />
                    <img src={Partner_9} alt="微众银行" />
                  </div>
                  <div className="logos">
                    <img src={Partner_5} alt="Kerry嘉里物流" />
                    <img src={Partner_10} alt="YASKAWA" />
                  </div>
                  <div className="logos">
                    <img src={Partner_11} alt="广汽" />
                    <img src={Partner_16} alt="宜家IKEA" />
                  </div>
                  <div className="logos">
                    <img src={Partner_12} alt="BASF" />
                    <img src={Partner_17} alt="piney bows" />
                  </div>
                </div>
              </li>
              <li className="aiot-products">
                <div className="slide-wrapper">
                  <div className="logos">
                    <img src={Partner_13} alt="Fedex" />
                    <img src={Partner_18} alt="NTT Data" />
                  </div>
                  <div className="logos">
                    <img src={Partner_14} alt="沃尔玛" />
                    <img src={Partner_19} alt="凯捷咨询" />
                  </div>
                  <div className="logos">
                    <img src={Partner_15} alt="长青集团" />
                    <img src={Partner_20} alt="菜鸟" />
                  </div>
                  <div className="logos">
                    <img src={Partner_21} alt="OMRON" />
                    <img src={Partner_25} alt="正大集团“" />
                  </div>
                  <div className="logos">
                    <img src={Partner_22} alt="SIEMENS" />
                    <img src={Partner_26} alt="GeorgiaPacific" />
                  </div>
                  <div className="logos">
                    <img src={Partner_23} alt="青岛啤酒" />
                    <img src={Partner_27} alt="UPS" />
                  </div>
                  <div className="logos">
                    <img src={Partner_24} alt="马士基Maersk" />
                    <img src={Partner_28} alt="首航新能源" />
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="glide__bullets" data-glide-el="controls[nav]">
            <button className="glide__bullet" data-glide-dir="=0"></button>
            <button className="glide__bullet" data-glide-dir="=1"></button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Partners
