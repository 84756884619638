import ReactPlayer from "react-player"
import React, { useEffect, useLayoutEffect, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import CoreApps from "../components/home/components/coreApps"
import SupplyChain from "../components/home/components/supplyChain"
import SuccessfulCases from "../components/home/components/successfulCases"
import Partners from "../components/home/components/partners"
import News from "../components/home/components/news"
import Misc from "../components/home/components/misc"
import MP4 from "../components/home/assets/intro-720.mp4"

import "./style.index.scss"

const IndexPage = ({ data }) => {
  const [hasWindow, setHasWindow] = useState(false)
  useLayoutEffect(() => {
    if (typeof window != "undefined") {
      setHasWindow(true)
    }
  }, [])

  return (
    <Layout>
      <Seo
        title="装箱软件_集装箱装柜软件_物流碳中和-蓝胖智汇"
        keywords="装箱软件,装柜软件,装箱装柜软件,集装箱装柜软件,物流碳中和,物流碳中和管理"
        description="蓝胖智汇专注自研装箱软件、装柜软件与物流碳中和管理，为客户提供装箱装柜软件、集装箱装柜软件的仓储物流场景的多项智化升级服务，帮助客户提高供应链决策效率，降低企业管理成本。"
      />
      <div className="relative">
        <StaticImage
          src="../images/doraopt-home.jpg"
          alt="装箱软件_集装箱装柜软件_物流碳中和-蓝胖智汇"
          className="w-full"
        />
        {hasWindow ? (
          <div className="player-wrapper">
            <ReactPlayer
              url={MP4}
              loop={true}
              muted={true}
              playing={true}
              width="100%"
              height="100%"
              className="react-player"
            />
          </div>
        ) : null}
      </div>

      <div className="zmm-home">
        <CoreApps />
        <SupplyChain />
        <SuccessfulCases />
        <Partners />
        <News data={data} />
        <Misc />
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HeaderQuery {
    allWpPost(sort: { fields: [date], order: DESC }, limit: 3) {
      edges {
        node {
          id
          excerpt
          uri
          date(formatString: "MMMM DD, YYYY")
          title
          excerpt
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 30
                    placeholder: TRACED_SVG
                    width: 280
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`
