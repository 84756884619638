import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

import "./style.news.scss"

import { GatsbyImage } from "gatsby-plugin-image"

function News({ data }) {
  console.log(data)
  return (
    <div id="aiot-introspection">
      <div className="title">行业洞察</div>
      <div className="card-wrapper">
        {data?.allWpPost.edges.map(({ node }) => (
          <div className="card" key={node.uri}>
            <GatsbyImage
              image={
                node.featuredImage?.node?.localFile?.childImageSharp
                  ?.gatsbyImageData
              }
              className="img"
              alt="2021首届国际碳中和30人论坛碳中和科创先锋奖"
              backgroundColor="transparent"
            />
            <div className="content">
              <div className="title">{node.title}</div>
              <div
                className="desc"
                dangerouslySetInnerHTML={{ __html: node.excerpt }}
              />
              <Link to={`/news/${node.id}`} itemProp="url">
                <div className="check check3">
                  查看详情 <span> {">>"} </span>
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
      <button className="check-more">
        <Link className="check" to="/news" href="#responsive-header">
          查看更多
        </Link>
      </button>
    </div>
  )
}

export default News
