import React, { useRef } from "react"
import { Link } from "gatsby"

import "./style.misc.scss"
import ContactModal from "../../contactModal"

function Misc() {
  const modalRef = useRef()
  return (
    <div id="misc">
      <div className="content">
        <div className="qa">
          <div className="title">其他问题</div>
          <div className="detail">
            告诉我们您需要解决什么问题，专家会帮助您找到最适合您的解决方案
          </div>
          <button
            className="contact-us-now"
            gtm-id="home-contact-us"
            onClick={() => modalRef.current.openModal()}
          >
            联系我们
          </button>
        </div>
        <div className="try">
          <div className="product">
            <div className="about">产品列表</div>
            <div className="go check-products">
              <Link className="check" to="/products" href="#responsive-header">
                立即查看
              </Link>
            </div>
          </div>
          <div className="product">
            <div className="about">成功案例</div>
            <div className="go check-cases">
              <Link className="check" to="/cases" href="#responsive-header">
                立即查看
              </Link>
            </div>
          </div>
        </div>
      </div>
      <ContactModal ref={modalRef} />
    </div>
  )
}

export default Misc
