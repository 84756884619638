import React from "react"

import "./style.successfulCases.scss"
import Asset1 from "../assets/成功案例_1.png"
import Asset2 from "../assets/成功案例_2.png"
import Asset3 from "../assets/成功案例_3.png"
import Asset4 from "../assets/成功案例_4.png"
import Asset5 from "../assets/成功案例_5.png"
import Engine from "../assets/engine.png"

function SuccessfulCases() {
  return (
    <div id="aiot-cases">
      <div className="title">成功案例</div>
      <div className="content">
        <div className="card card1">
          <div className="first">
            <img src={Asset1} className="img" alt="icon" />
            <div className="industry">整车&零部件行业</div>
          </div>
          <div className="text">
            <div className="title">亮点解决方案</div>
            <p>1、汽车KD包装方案(单件/单套）</p>
            <p>2、玻璃、轮胎、结构件类型产品装箱解决方案</p>
            <p>3、铁路货柜配平装箱解决方案</p>
          </div>
        </div>
        <div className="card card2">
          <div className="first">
            <img src={Asset2} className="img" alt="icon" />
            <div className="industry">零售行业</div>
          </div>
          <div className="text">
            <div className="title">亮点解决方案</div>
            <p>1、多点取货拼箱方案</p>
            <p>2、多SKU实时混码</p>
          </div>
        </div>
        <div className="card card3">
          <div className="first">
            <img src={Asset3} className="img" alt="icon" />
            <div className="industry">化工行业</div>
          </div>
          <div className="text">
            <div className="title">亮点解决方案</div>
            <p>1、危险品运输装箱方案</p>
            <p>2、产销运协同解决方案</p>
          </div>
        </div>
        <div className="card card4">
          <div className="first">
            <img src={Asset4} className="img" alt="icon" />
            <div className="industry">机械制造行业</div>
          </div>
          <div className="text">
            <div className="title">亮点解决方案</div>
            <p>1、散件混拼装箱方案</p>
            <p>2、特定异形件装箱方案</p>
          </div>
        </div>
        <div className="card card5">
          <div className="first">
            <img src={Asset5} className="img" alt="小家电" />
            <div className="industry">家居&家电行业</div>
          </div>
          <div className="text">
            <div className="title">亮点解决方案</div>
            <p>1、产品混合码垛方案</p>
            <p>2、库位优化方案</p>
            <p>3、智能审单监装方案</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuccessfulCases
